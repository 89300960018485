import CardRail from '../CardRail';
import ThemeCard from '../ThemeCard';

import type { HotelsNearPOIsInCityCardsProps } from 'common-types/types/hotels-components/HotelsNearPOIsInCityCardsProps';

const HotelsNearPOIsInCityCards = ({
  cards,
  subtitle,
  title,
}: HotelsNearPOIsInCityCardsProps) => {
  if (cards.length <= 0) {
    return null;
  }

  return (
    <div className="HotelsNearPOIsInCityCards">
      <CardRail title={title} subtitle={subtitle}>
        {cards.map((card) => (
          <ThemeCard key={card.title} {...card} />
        ))}
      </CardRail>
    </div>
  );
};

export default HotelsNearPOIsInCityCards;
