import BpkBadge, {
  BADGE_TYPES,
} from '@skyscanner/backpack-web/bpk-component-badge';
import BpkCard from '@skyscanner/backpack-web/bpk-component-card';
import BpkImage, {
  withLazyLoading,
} from '@skyscanner/backpack-web/bpk-component-image';
import BpkPrice, {
  SIZES,
  ALIGNS,
} from '@skyscanner/backpack-web/bpk-component-price';
import BpkText, {
  TEXT_STYLES,
} from '@skyscanner/backpack-web/bpk-component-text';

import type { ThemeCardProps } from 'common-types/types/ThemeCardProps';

import STYLES from './ThemeCard.module.scss';

const documentIfExists = typeof window !== 'undefined' ? document : null;
const LazyLoadedImage = withLazyLoading(BpkImage, documentIfExists);
const IMG_WIDTH = 392;
const IMG_HEIGHT = 184;

const ThemeCard = ({
  badges = undefined,
  imageAltText,
  imageSrc,
  leadingText,
  noPriceString,
  price,
  redirectUrl,
  subtitle = undefined,
  textBody = undefined,
  title,
  trailingText = undefined,
}: ThemeCardProps) => (
  <div className={STYLES.ThemeCardWrapper}>
    <BpkCard
      padded={false}
      className={STYLES.ThemeCard}
      href={redirectUrl}
      rel="nofollow"
    >
      <LazyLoadedImage
        className={STYLES.ThemeCard__themeCardImage}
        aspectRatio={IMG_WIDTH / IMG_HEIGHT}
        altText={imageAltText}
        src={imageSrc}
      />
      <div className={STYLES.ThemeCard__main}>
        <div className={STYLES.ThemeCard__header}>
          <div className={STYLES.ThemeCard__leftAligned}>
            <div className={STYLES.ThemeCard__title}>
              <BpkText tagName="h3" textStyle={TEXT_STYLES.heading4}>
                {title}
              </BpkText>
              {subtitle && (
                <BpkText
                  textStyle={TEXT_STYLES.caption}
                  className={STYLES.ThemeCard__subtitle}
                >
                  {subtitle}
                </BpkText>
              )}
            </div>

            <div className={STYLES.ThemeCard__badges}>
              {badges?.map((badge) => (
                <BpkBadge
                  key={badge.title}
                  type={BADGE_TYPES.normal}
                  aria-label={badge.ariaLabel}
                >
                  {badge.title}
                </BpkBadge>
              ))}
            </div>
          </div>

          <div className={STYLES.ThemeCard__rightAligned}>
            {price ? (
              <BpkPrice
                price={price}
                size={SIZES.small}
                leadingText={leadingText}
                align={ALIGNS.right}
                trailingText={trailingText}
              />
            ) : (
              /* to make sure we occupy the same vertical space */ <BpkPrice
                price="&nbsp;"
                size={SIZES.small}
                leadingText={noPriceString}
                align={ALIGNS.right}
                trailingText="&nbsp;"
              />
            )}
          </div>
        </div>
        {textBody && (
          <BpkText
            className={STYLES.ThemeCard__textBody}
            tagName="p"
            textStyle={TEXT_STYLES.bodyDefault}
          >
            {textBody}
          </BpkText>
        )}
      </div>
    </BpkCard>
  </div>
);

export default ThemeCard;
