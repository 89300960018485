import type { ReactNode, PropsWithChildren } from 'react';

import BpkMobileScrollContainer from '@skyscanner/backpack-web/bpk-component-mobile-scroll-container';
import BpkSectionHeader from '@skyscanner/backpack-web/bpk-component-section-header';

import DesktopScrollContainer from '../common/DesktopScrollContainer/DesktopScrollContainer';

import STYLES from './CardRail.module.scss';

type CardRailProps = PropsWithChildren<{
  title: string;
  subtitle: string;
  children: JSX.Element[];
  callToAction?: ReactNode;
}>;

const CardRail = ({
  callToAction = null,
  children = [],
  subtitle,
  title,
}: CardRailProps) => (
  <div className={STYLES.CardRail}>
    <div className={STYLES.header}>
      <BpkSectionHeader title={title} description={subtitle} />
    </div>
    {/*
      We first tried using BpkBreakpoint to switch between BpkMobileScrollContainer and DesktopScrollContainer
      But find it not possible for the server-side rendering page of hotels-in-city,
      because the server doesn’t have the window or concept of what to apply until it hits the user device.
      So we move to using style breakpoint to switch between the components.
      Refer to the thread:
      https://skyscanner.slack.com/archives/C0305N26PQC/p1702472300782129?thread_ts=1702383597.603779&cid=C0305N26PQC
    */}
    <div className={STYLES.mobile_scroll_container_hider}>
      <BpkMobileScrollContainer>
        <div className={STYLES.mobile_scroll_container_body}>{children}</div>
      </BpkMobileScrollContainer>
    </div>
    <DesktopScrollContainer nextLabel="next" prevLabel="prev">
      {children}
    </DesktopScrollContainer>
    {callToAction}
  </div>
);

export default CardRail;
